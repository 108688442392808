import { DefaultXRControllers, Interactive, useXR, useXREvent, VRCanvas } from '@react-three/xr'
import { Box } from 'drei/shapes'
import { OrbitControls } from 'drei/OrbitControls'
import { Text } from 'drei/Text'
import React, { useMemo, useState, useEffect } from 'react'
import { useFrame } from 'react-three-fiber'
import { BufferGeometry, InterleavedBuffer, InterleavedBufferAttribute, Vector3 } from 'three'

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyC2rfrd6fOYCN3Zl98dKvctTOt0HiGzFuw",
  authDomain: "cms-enjoyee.firebaseapp.com",
  projectId: "cms-enjoyee",
  storageBucket: "cms-enjoyee.appspot.com",
  messagingSenderId: "385934557914",
  appId: "1:385934557914:web:b6de0be4fe02a7c721dbe3"
};

// Initialize Firebase
initializeApp(firebaseConfig);

const count = 100_000
const stride = 13
const total = count * stride
const dots = new Float32Array(total)

const random = (min: number, max: number) => Math.random() * (max - min) + min

const offset = [-0.7, 0.5, 0.09]
// Init
{
  let i
  for (i = 0; i < total; i += stride) {
    let d = 8
    let x = random(-1, 1) * d
    let y = random(-1, 1) * d
    let z = random(-1, 1) * d
    // home position
    dots[i] = x + offset[0]
    dots[i + 1] = y + offset[1]
    dots[i + 2] = z + offset[2] - 0.1
    // position
    dots[i + 3] = x + offset[0]
    dots[i + 4] = y + offset[1]
    dots[i + 5] = z + offset[2]
    // velocity
    dots[i + 6] = 0
    dots[i + 7] = 0
    dots[i + 8] = 0
    // damp
    dots[i + 9] = random(0.005, 0.008)
  }
}

const setPointcloud = (indata: string | false) => {
  let data: string[] = []
  if (indata) {
    data = indata.split('\n');
  }

  for (let i = 0; i < Math.min(data.length, total); i++) {
    const line = data[i].trim();
    if (line !== '') {
      const values = line.split(' ');
      const x = parseFloat(values[0]) * 0.15;
      const y = parseFloat(values[2]) * 0.15;
      const z = parseFloat(values[1]) * 0.15;
      const r = parseFloat(values[3]);
      const g = parseFloat(values[4]);
      const b = parseFloat(values[5]);

      const index = i * stride;
      dots[index] = x + offset[0];
      dots[index + 1] = y + offset[1];
      dots[index + 2] = z + offset[2];
      // Set color values if needed
      dots[index + 10] = r / 255; // Convert color values to the range of 0-1
      dots[index + 11] = g / 255;
      dots[index + 12] = b / 255;
    }
  }
};


const pointerL = {
  ignore: false,
  prev: new Vector3(),
  position: new Vector3(),
  force: 0
}

const pointerR = {
  ignore: false,
  prev: new Vector3(),
  position: new Vector3(),
  force: 0
}

const step = () => {
  let i
  for (i = 0; i < total; i += stride) {
    const pl = pointerL.position
    const dxl = pl.x - dots[i + 3]
    const dyl = pl.y - dots[i + 4]
    const dzl = pl.z - dots[i + 5]
    const distanceSquaredL = dxl * dxl + dyl * dyl + dzl * dzl

    if (pointerL.force > 0) {
      const powerMult = Math.max(Math.min(128, pointerL.prev.distanceTo(pointerL.position) * 256), 1)
      const dsq = (1 / (Math.max(0.1, distanceSquaredL) * 16)) * pointerL.force * powerMult

      dots[i + 6] += dxl * dsq
      dots[i + 7] += dyl * dsq
      dots[i + 8] += dzl * dsq
    } else if (distanceSquaredL < 0.01) {
      dots[i + 6] -= dxl * 16
      dots[i + 7] -= dyl * 16
      dots[i + 8] -= dzl * 16
    }

    const pr = pointerR.position
    const dxr = pr.x - dots[i + 3]
    const dyr = pr.y - dots[i + 4]
    const dzr = pr.z - dots[i + 5]
    const distanceSquaredR = dxr * dxr + dyr * dyr + dzr * dzr

    if (pointerR.force > 0) {
      const powerMult = Math.max(Math.min(128, pointerR.prev.distanceTo(pointerR.position) * 256), 1)
      const dsq = (1 / (Math.max(0.1, distanceSquaredR) * 16)) * pointerR.force * powerMult

      dots[i + 6] += dxr * dsq
      dots[i + 7] += dyr * dsq
      dots[i + 8] += dzr * dsq
    } else if (distanceSquaredR < 0.01) {
      dots[i + 6] -= dxr * 16
      dots[i + 7] -= dyr * 16
      dots[i + 8] -= dzr * 16
    }

    const returnForce = 0.045
    if (pointerL.force === 0 && pointerR.force === 0) {
      dots[i + 6] += (-dots[i + 3] + dots[i]) * returnForce
      dots[i + 7] += (-dots[i + 4] + dots[i + 1]) * returnForce
      dots[i + 8] += (-dots[i + 5] + dots[i + 2]) * returnForce
    }

    const hm = 0.982
    dots[i + 6] *= hm
    dots[i + 7] *= hm
    dots[i + 8] *= hm

    const r = pointerL.force === 0 && pointerR.force === 0 ? 0.0005 : 0.03
    dots[i + 6] += random(-r, r)
    dots[i + 7] += random(-r, r)
    dots[i + 8] += random(-r, r)

    // apply force
    dots[i + 3] += dots[i + 6] * dots[i + 9]
    dots[i + 4] += dots[i + 7] * dots[i + 9]
    dots[i + 5] += dots[i + 8] * dots[i + 9]
  }
}

function Button({ children, onSelect, position }: { children: string; onSelect: any; position: any }) {
  const [hover, setHover] = useState(false)

  return (
    <Interactive
      onSelect={onSelect}
      onHover={(e) => {
        setHover(true)
        const pointer = e.controller.inputSource.handedness === 'left' ? pointerL : pointerR
        pointer.ignore = true
      }}
      onBlur={(e) => {
        setHover(false)
        const pointer = e.controller.inputSource.handedness === 'left' ? pointerL : pointerR
        pointer.ignore = false
      }}>
      <Box args={[0.12, 0.05, 0.01]} position={position}>
        <meshPhongMaterial color={hover ? '#669' : '#666'} wireframe />
        <Text fontSize={0.03} position={[0, 0, 0.006]}>
          {children}
        </Text>
      </Box>
    </Interactive>
  )
}

function DotsRender(indata: any) {
  const { buffer, geometry } = useMemo(() => {
    const geometry = new BufferGeometry()
    const buffer = new InterleavedBuffer(dots, stride)
    const position = new InterleavedBufferAttribute(buffer, 3, 3)
    const color = new InterleavedBufferAttribute(buffer, 3, 10)

    geometry.setAttribute('position', position)
    geometry.setAttribute('color', color)

    return { buffer, position, geometry }
  }, [])

  useFrame(() => {
    buffer.needsUpdate = true
  })

  return (
    <>
      <points frustumCulled={false}>
        <primitive object={geometry} attach="geometry" />
        <pointsMaterial size={3} vertexColors sizeAttenuation={false} />
      </points>
      <group position={[0, 1, 0]} rotation={[-0.7, 0, 0]}>
        <Button position={[-0.4, 0, -0.3]} onSelect={() => setPointcloud(indata[0])}>
          scene 1
        </Button>
        <Button position={[-0.2, 0, -0.3]} onSelect={() => setPointcloud(indata[1])}>
          scene 2
        </Button>
        <Button position={[0.0, 0, -0.3]} onSelect={() => setPointcloud(indata[2])}>
          scene 3
        </Button>
        <Button position={[0.2, 0, -0.3]} onSelect={() => setPointcloud(indata[3])}>
          scene 4
        </Button>
        <Button position={[0.4, 0, -0.3]} onSelect={() => setPointcloud(indata[4])}>
          scene 5
        </Button>

      </group>
    </>
  )
}

function Dots() {
  const { controllers } = useXR()

  useXREvent('selectstart', (e) => {
    const pointer = e.controller.inputSource.handedness === 'left' ? pointerL : pointerR

    if (pointer.ignore) return

    pointer.force = 1
    pointer.prev.copy(e.controller.controller.position)
    pointer.position.copy(e.controller.controller.position)
  })
  useXREvent('selectend', (e) => {
    const pointer = e.controller.inputSource.handedness === 'left' ? pointerL : pointerR
    pointer.force = 0
  })

  useFrame(() => {
    controllers.forEach(({ inputSource, controller }) => {
      const pointer = inputSource.handedness === 'left' ? pointerL : pointerR
      pointer.prev.copy(pointer.position)
      pointer.position.copy(controller.position)
    })

    step()
  })

  return null
}



export function App() {
  const [fileContents, setFileContents] = useState<string[]>([]);

  useEffect(() => {
    const importAll = (r: any) => r.keys().map(r);
    const textFiles = importAll(require.context('./pointcloud', false, /\.txt$/));

    const readTextFiles = async () => {
      const filePromises: Promise<string>[] = [];

      for (const filePath of textFiles) {
        const filePromise = fetch(filePath) // Load the file content using fetch or any other method of your choice
          .then(response => response.text());
        filePromises.push(filePromise);
      }

      const contents = await Promise.all(filePromises);
      setFileContents(contents);
    };

    readTextFiles();
  }, []);

  console.log(fileContents);
  setPointcloud(fileContents && fileContents.length > 0 && fileContents[0])

  return (
    <>
      <div id="intro">
        <button onClick={() => setPointcloud(fileContents.length > 0 && fileContents[0])}>Scene 1</button>
        <button onClick={() => setPointcloud(fileContents.length > 1 && fileContents[1])}>Scene 2</button>
        <button onClick={() => setPointcloud(fileContents.length > 2 && fileContents[2])}>Scene 3</button>
        <button onClick={() => setPointcloud(fileContents.length > 3 && fileContents[3])}>Scene 4</button>
        <button onClick={() => setPointcloud(fileContents.length > 4 && fileContents[4])}>Scene 5</button>
        <h1>WebXR point cloud demo</h1>
        <p>This is a simple point cloud viewer built on top of <a href='https://github.com/sniok/webxr-particles'>https://github.com/sniok/webxr-particles</a>.
          The displayed point clouds are sections of the <a href='https://www.cvlibs.net/datasets/kitti-360/'>KITTI 360 Dataset</a>. The main part of this
          project is a Unity VR Labeling tool - <a href="https://github.com/VojtaSara/VR_labeler.git">repository w/ thesis</a>.
          This page serves as a showcase and a basis for a future WebXR port of the application.</p>
      </div>
      <VRCanvas>
        <DefaultXRControllers />

        <OrbitControls enablePan={false} autoRotate={true} />
        <Dots />
        <ambientLight />
        <DotsRender indata={fileContents.length > 0 && fileContents} />
        <pointLight position={[10, 10, 10]} />
        <color args={[0x000000] as any} attach="background" />
      </VRCanvas>
    </>
  )
}
